import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Divider, IconButton, Link, Toolbar } from '@mui/material';
import MenuIcon from '../icons/Menu';
import Logo from './Logoblack';
import useAuth from '../hooks/useAuth';

function MainNavbar(props) {
  const { onSidebarMobileOpen } = props;
  const { user } = useAuth();

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: 'none'
            }
          }}
          size="large"
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                md: 'inline',
                xs: 'none'
              },
              height: 40,
              width: 40
            }}
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            }
          }}
        >
          <Box
            sx={{
              mx: 2
            }}
          >
            <Link
              color="textSecondary"
              underline="none"
              variant="body1"
              href={process.env.REACT_APP_URL_CLIENTES}
            >
              Clientes
            </Link>
          </Box>
          <Link
            color="textSecondary"
            underline="none"
            variant="body1"
          >
            Ayuda
          </Link>
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2
            }}
          />
          {/* eslint-disable */
            user ? <Button
              color="primary"
              component={RouterLink}
              to="/panel"
              variant="contained"
              size="small"
            >
              Panel de Control
            </Button> : <> <Button
              color="primary"
              component={RouterLink}
              to="/panel"
              variant="contained"
              size="small"
            >
              Ingresar
            </Button>
            </>
          /* eslint-enable */}
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
}

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;

import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { experimentalStyled, useTheme } from '@mui/material/styles';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import ContentSearch from './ContentSearch';
import Logo from '../Logo';
import LogoMobile from '../LogoMobile';
import NotificationsPopover from './NotificationsPopover';
import { db } from '../../lib/firebase';
import useAuth from '../../hooks/useAuth';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer - 1 // era + 100
}));

const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [lastNotificationsFetched, setLastNotificationsFetched] = useState(null);
  const { user } = useAuth();
  // console.log(notifications);

  useEffect(() => {
    let notificationsload = [];
    const query = db
      .collection('users')
      .doc(user.uid)
      .collection('notifications')
      .orderBy('createdAt', 'desc')
      .limit(100);

    const unsubRef = query.onSnapshot((snapshot) => {
      notificationsload = snapshot.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id; // eslint-disable-line
        return { id, ...data };
      });

      setNotifications(notificationsload);

      if (snapshot.docs.length > 0) {
        const last = snapshot.docs[snapshot.docs.length - 1];
        setLastNotificationsFetched(last);
      }
    });

    return () => unsubRef();
  }, []);

  const fetchMoreNotifications = () => {
    let notificationsload = [];
    const query = db
      .collection('users')
      .doc(user.uid)
      .collection('notifications')
      .orderBy('createdAt', 'desc')
      .limit(100)
      .startAfter(lastNotificationsFetched);

    const unsubRef = query.onSnapshot((snapshot) => {
      notificationsload = snapshot.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id; // eslint-disable-line
        return { id, ...data };
      });

      if (snapshot.docs.length > 0) {
        setNotifications(notificationsload);

        if (snapshot.docs.length > 0) {
          const last = snapshot.docs[snapshot.docs.length - 1];
          setLastNotificationsFetched(last);
        }
      }
    });

    return () => unsubRef();
  };

  const fetchGoBackNotifications = () => {
    let notificationsload = [];
    const query = db
      .collection('users')
      .doc(user.uid)
      .collection('notifications')
      .orderBy('createdAt', 'desc')
      .limit(100);

    const unsubRef = query.onSnapshot((snapshot) => {
      notificationsload = snapshot.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id; // eslint-disable-line
        return { id, ...data };
      });

      setNotifications(notificationsload);

      if (snapshot.docs.length > 0) {
        const last = snapshot.docs[snapshot.docs.length - 1];
        setLastNotificationsFetched(last);
      }
    });

    return () => unsubRef();
  };

  return [notifications, lastNotificationsFetched, { fetchMoreNotifications, fetchGoBackNotifications }];
};

function DashboardNavbar(props) {
  const { onSidebarMobileOpen, ...other } = props;
  const [notifications, lastNotificationsFetched, { fetchMoreNotifications, fetchGoBackNotifications }] = useNotifications();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust 'md' breakpoint as needed
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  // console.log(notifications);
  // console.log(user);
  const handleClick = (id) => {
    setOpen(false);
    db.collection('users').doc(user.uid).collection('notifications').doc(id)
      .update({
        notificationRead: true
      }, (error) => {
        if (error) {
          console.log({ msg: 'Error setting read false to notification', user: user.uid, notification: id });
        }
      });
  };

  const handleClickMultipleMarkRead = (id) => {
    setOpen(false);
    notifications.forEach((doc) => {
      db.collection('users').doc(user.uid).collection('notifications').doc(doc.id)
        .update({
          notificationRead: true
        }, (error) => {
          if (error) {
            console.log({ msg: 'Error setting read false to notification', user: user.uid, notification: id });
          }
        });
    });
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        {/* <IconButton change
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
          size="large"
        > */}
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          size="large"
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Box sx={{
            display: 'flex',
            alignItems: 'center', // This will vertically center the logo
            height: '100%' // Take the full height of the toolbar
          }}
          >
            {isMobile ? (
              <LogoMobile sx={{ display: { xs: 'none' }, height: 20, width: 40 }} />
            ) : (
              <Logo sx={{ display: { xs: 'none' }, height: 20, width: 40 }} />
            )}
          </Box>
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover handleClick={handleClick} handleClickMultipleMarkRead={handleClickMultipleMarkRead} notifications={notifications} lastNotificationsFetched={lastNotificationsFetched} fetchMoreNotifications={fetchMoreNotifications} fetchGoBackNotifications={fetchGoBackNotifications} open={open} setOpen={setOpen} />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
}

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;

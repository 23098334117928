import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { firebaseConfig } from '../config';

let db; // eslint-disable-line
let storage; // eslint-disable-line

if (!firebase.apps.length) {
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  db = firebaseApp.firestore();
  /* db.enablePersistence() // Da problemas, al final es mal lento que hacer queries online a "documents"
    .catch((err) => {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.log(err.code);
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.log(err.code);
      }
    }); */
  storage = firebaseApp.storage();
}

export default firebase;
export { db, storage };

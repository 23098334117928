import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, ListSubheader } from '@mui/material';
import NavItem from './NavItem';
import CheckRole from '../utils/checkRole';

function checkChildren(item) {
  // console.log(item);
  const results = [];
  if (item.children.some((obj) => 'permiso' in obj && CheckRole(obj.permiso))) { results.push(true); } else { results.push(false); }

  return (results.includes(true));
}

const renderNavItems = ({ depth = 0, items, pathname }) => (
  <List disablePadding>
    {items.reduce( // eslint-disable-line
      // eslint-disable-next-line no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth
      }), [])}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, pathname) : false;

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;

    acc.push(checkChildren(item) && ( // Check permiso
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </NavItem>
    ));
  } else {
    acc.push(CheckRole(item.permiso) && ( // Check permiso
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />
    ));
  }

  return acc;
};

function NavSection(props) {
  const { items, pathname, title, ...other } = props;

  // Reviso si hay algun children con permiso si no no presento el nombre de sección
  const checkNavSection = items.map((value) => CheckRole(value.permiso));
  // console.log(checkNavSection);

  if (checkNavSection.includes(true)) {
    return (
      <List
        subheader={(
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              color: 'text.primary',
              fontSize: '1rem',
              lineHeight: 1.5,
              fontWeight: 500, // era 700 change
              textTransform: 'uppercase'
            }}
          >
            {title}
          </ListSubheader>
        )}
        {...other}
      >
        {renderNavItems({
          items,
          pathname
        })}
      </List>
    );
  }
  return null;
}

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
};

export default NavSection;

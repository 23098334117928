import { useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import Footer from './Footer';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';
import Scrollbar from './Scrollbar';

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64
}));

function MainLayout({ children }) {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  return (
    <MainLayoutRoot>
      <div style={{ position: 'relative', overflow: 'auto', height: '100%' }}>
        <Scrollbar>
          <MainNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
          <MainSidebar
            onMobileClose={() => setIsSidebarMobileOpen(false)}
            openMobile={isSidebarMobileOpen}
          />
          {children || <Outlet />}
          <Footer />
        </Scrollbar>
      </div>
    </MainLayoutRoot>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;

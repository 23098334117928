import { createContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
import { db } from '../lib/firebase';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es';

dayjs.extend(utc);

const ParametersContext = createContext({
  parametrosDB: null,
  usersDB: null,
  timeDatesDB: null,
  // clientsDB: null,
});

export function ParametersProvider(props) {
  const { children } = props;
  const [isLoading, setIsLoading] = useState(0);
  const [parametrosDB, setParametrosDB] = useState(null);
  const [usersDB, setUsersDB] = useState([]);
  // const [clientsSucursalesDB, setClientsSucursalesDB] = useState([]);
  // const [clientsEjecutivoDB, setClientsEjecutivoDB] = useState([]);
  const [timeDatesDB, setTimeDatesDB] = useState('');
  // const [clientsDB, setClientsDB] = useState([]);
  const { user, isAuthenticated } = useAuth();
  const unsubFromParametersRef = useRef();
  const unsubFromUsersRef = useRef();
  // const unsubFromClientsSucursalesRef = useRef();
  // const unsubFromClientsEjecutivoRef = useRef();
  const unsubFromTimeDatesRef = useRef();
  // console.log(user);

  const restoreParametros = async () => {
    try { // Listener para parametros
      setIsLoading((prevValue) => ++prevValue);
      const parametersUnsubscribe = db.collection('params').doc('global')
        .onSnapshot((docSnapshot) => {
          const initialParams = docSnapshot.data();
          const Sucursales = initialParams.sucursal;
          const Organizaciones = initialParams.organizacion;
          // console.log(initialSucursales);
          // console.log(initialOrganizaciones);
          // console.log(user);
          const userSucursales = Sucursales.filter((obj) => user.sucu.includes(obj.value)); // Del auth token saco las sucursales del usuario
          const userOrganizaciones = Organizaciones.filter((obj) => (userSucursales.map((obj2) => obj2.org)).includes(obj.value)); // De las mismas sucursales del auth token saco las organizaciones del usuario
          if (!userSucursales.find((obj) => obj.value === user.sucursal.value)) {
            userSucursales.push(user.sucursal);
          }
          // console.log(userSucursales);
          if (!userOrganizaciones.find((obj) => obj.value === user.organizacion.value)) {
            userOrganizaciones.push(user.organizacion);
          }
          // console.log(userOrganizaciones);
          const parametrosTotal = initialParams;
          parametrosTotal.sucursalUser = userSucursales;
          parametrosTotal.organizacionUser = userOrganizaciones;
          // console.log(parametrosTotal);
          setParametrosDB(parametrosTotal);
          setIsLoading((prevValue) => --prevValue);
        });
      unsubFromParametersRef.current = parametersUnsubscribe;
      return null;
    } catch (error) {
      console.error('Error setting parameters context: ', error);
      return null;
    }
  };

  const restoreUsers = async () => {
    try { // Listener para usuarios
      setIsLoading((prevValue) => ++prevValue);
      const usersUnsubscribe = db.collection('users')
        .where('externo', '!=', true)
        .onSnapshot((querySnapshot) => {
          const users = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
          setUsersDB(users);
          setIsLoading((prevValue) => --prevValue);
        });
      unsubFromUsersRef.current = usersUnsubscribe;
      return null;
    } catch (error) {
      console.error('Error setting users context: ', error);
      return null;
    }
  };

  /* const restoreClients = async () => {
    try { // Listener para clientes por permiso de sucursal
      if (user.sucu.length > 1) {
        setIsLoading((prevValue) => ++prevValue);
        const clientSucursalesUnsubscribe = db.collection('clients').orderBy('modificationTime', 'desc').where('sucursal.value', 'in', user.sucu)
          .onSnapshot((querySnapshot) => {
            const clientsload1 = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
            // console.log(clientsload1);
            setClientsSucursalesDB(clientsload1);
            setIsLoading((prevValue) => --prevValue);
          });
        unsubFromClientsSucursalesRef.current = clientSucursalesUnsubscribe;
      }
      // Listener para clientes por permiso de ejecutivo
      setIsLoading((prevValue) => ++prevValue);
      const clientEjecutivoUnsubscribe = db.collection('clients').orderBy('modificationTime', 'desc').where('ejecutivoUIDs', 'array-contains', user.uid)
        .onSnapshot((querySnapshot) => {
          const clientsload2 = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
          // console.log(clientsload2);
          setClientsEjecutivoDB(clientsload2);
          setIsLoading((prevValue) => --prevValue);
        });
      unsubFromClientsEjecutivoRef.current = clientEjecutivoUnsubscribe;
      return null;
    } catch (error) {
      console.error('Error setting clients context: ', error);
      return null;
    }
  }; */

  const restoreTimeDates = async () => {
    try { // Listener para server dates
      setIsLoading((prevValue) => ++prevValue);
      const datesUnsubscribe = db.collection('params').doc('timedates')
        .onSnapshot((docSnapshot) => {
          const timeDates = docSnapshot.data();
          // console.log(timeDates);
          setTimeDatesDB(timeDates);
          setIsLoading((prevValue) => --prevValue);
        });
      unsubFromTimeDatesRef.current = datesUnsubscribe;
      return null;
    } catch (error) {
      console.error('Error setting parameters context: ', error);
      return null;
    }
  };

  const getData = async () => {
    restoreParametros();
    restoreUsers();
    restoreTimeDates();
    // restoreClients();
  };

  /* useEffect(() => { // Elimino potenciales clientes duplicados por permiso de sucursal o por permiso de ejecutivo
    const clientsUnicas = [...clientsSucursalesDB, ...clientsEjecutivoDB].filter((v, i, a) => a.findIndex((v2) => (v2.id === v.id)) === i);
    // console.log(clientsUnicas);
    setClientsDB(clientsUnicas);
  }, [clientsSucursalesDB, clientsEjecutivoDB]); */

  useEffect(() => {
    // console.log(isAuthenticated);
    if (isAuthenticated) {
      getData();
    }
    return () => {
      if (unsubFromParametersRef.current) { unsubFromParametersRef.current(); }
      if (unsubFromUsersRef.current) { unsubFromUsersRef.current(); }
      // if (unsubFromClientsSucursalesRef.current) { unsubFromClientsSucursalesRef.current(); }
      // if (unsubFromClientsEjecutivoRef.current) { unsubFromClientsEjecutivoRef.current(); }
      if (unsubFromTimeDatesRef.current) { unsubFromTimeDatesRef.current(); }
      setParametrosDB(null);
      setUsersDB([]);
      // setClientsSucursalesDB([]);
      // setClientsEjecutivoDB([]);
      setTimeDatesDB([]);
      // setClientsDB([]);
    };
  }, [isAuthenticated]);

  return (
    <ParametersContext.Provider
      value={{ // eslint-disable-line
        parametrosDB,
        usersDB,
        timeDatesDB,
        // clientsDB,
      }}
    >
      {isLoading > 0 ? (
        <LoadingScreen />
      ) : (
        [children]
      )}
    </ParametersContext.Provider>
  );
}

ParametersProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const ParametersConsumer = ParametersContext.Consumer;

export default ParametersContext;

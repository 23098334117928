import useAuth from '../hooks/useAuth';

const CheckRole = (funcion) => {
  const { user } = useAuth();

  const regex = new RegExp(funcion); // eslint-disable-line
  return regex.test(user.role);
};

export default CheckRole;

import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        },
        '#nprogress .bar': {
          zIndex: '2000 !important'
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 1300,
        },
      },
    },
  },
  typography: {
    // htmlFontSize: '16', // change original 16
    // fontSize: '14', // change original 14
    button: {
      fontWeight: 600
    },
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem' // change era '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem' // change era '3rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem' // change era '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem' // change era '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem' // change era '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem' // change era '1.125rem'
    },
    overline: {
      fontWeight: 600
    },
    caption: {
      fontSize: '0.9rem' // change era '0.9rem'
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1rem' // change no estaba
    },
    subtitle2: {
      fontSize: '0.9rem'
    },
    body1: { // change no estaba
      fontSize: '1rem'
    },
    body2: { // change no estaba
      fontSize: '0.875rem'
    }
  }
  /* typography: {
    button: {
      fontWeight: 600
    },
    htmlFontSize: 16,
    pxToRem: f(),
    fontSize: 14,
    fontFamily: '"Robot", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: '6rem' // change era '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3.75rem' // change era '3rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '3rem' // change era '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2.125rem' // change era '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem' // change era '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.25rem' // change era '1.125rem'
    },
    overline: {
      fontWeight: 600
    },
    caption: {
      fontSize: '0.9rem' // change era '0.9rem'
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1rem' // change no estaba
    },
    subtitle2: {
      fontSize: '0.875rem' // change era '0.9rem'
    },
    body1: { // change no estaba
      fontSize: '1rem'
    },
    body2: { // change no estaba
      fontSize: '0.875rem'
    }
  } */
};

const themesOptions = {
  [THEMES.CLARO]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      backgroundTextFieldDisabled: {
        default: '#f5f5f5',
      },
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#f4f5f7',
        paper: '#ffffff'
      },
      error: {
        contrastText: '#ffffff',
        main: '#d32f2f'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#3f51b5' // era '#5664d2' change
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#e65100'
      },
      danger: {
        contrastText: '#ffffff',
        main: '#f50057'
      },
      info: {
        contrastText: '#ffffff',
        main: '#0288d1' // era #03a9f4 change
      },
      info2: {
        contrastText: '#172b4d',
        main: '#e3f2fd' // nuevo
      },
      secondary: { // afecta select en react-table-core
        main: '#616161',
      },
    },
    shadows: lightShadows
  },
  [THEMES.OSCURO]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: '#CCCCCC', // Match your text primary color
            '&:hover': {
              backgroundColor: 'rgba(204, 204, 204, 0.08)', // Slight highlight on hover
            },
          },
        },
      },
    },
    palette: {
      backgroundTextFieldDisabled: {
        default: '#222b36',
      },
      background: {
        default: '#171c24',
        paper: '#222b36'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#d32f2f'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#3f51b5' // era '#688eff' change
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#CCCCCC', // era '#ffffff' change
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#e65100'
      },
      danger: {
        contrastText: '#ffffff',
        main: '#f50057'
      },
      info: {
        contrastText: '#ffffff',
        main: '#0288d1' // era #03a9f4 change
      },
      info2: {
        contrastText: '#ffffff',
        main: '#919eab' // nuevo
      },
      secondary: { // afecta select en react-table-core
        main: '#3f51b5',
      },
    },
    shadows: darkShadows
  },
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.CLARO];
  }

  let theme = createTheme(merge({}, baseOptions, themeOptions, {
    ...(config.roundedCorners && {
      shape: {
        borderRadius: 16
      }
    })
  }));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

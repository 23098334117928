import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/FirebaseContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { ParametersProvider } from './contexts/ParametersContext';

ReactDOM.render( // eslint-disable-line
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <SettingsProvider>
          <BrowserRouter>
            <AuthProvider>
              <ParametersProvider>
                <App />
              </ParametersProvider>
            </AuthProvider>
          </BrowserRouter>
        </SettingsProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>, document.getElementById('root'));

import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { THEMES } from '../constants'; // eslint-disable-line
import useSettings from '../hooks/useSettings';

const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  openDrawer: settings.openDrawer,
  theme: settings.theme
});

const SettingsDrawer = () => { // eslint-disable-line
  const { settings, saveSettings } = useSettings();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(getValues(settings));

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleClose = () => {
    saveSettings({
      ...values,
      openDrawer: false
    });
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings({
      ...values,
      openDrawer: false
    });
    setOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      onClose={handleClose}
      open={open || values.openDrawer}
      PaperProps={{
        sx: {
          p: 2,
          width: 320
        }
      }}
    >
      <Typography
        color="textPrimary"
        variant="h6"
      >
        Opciones
      </Typography>
      <Box sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="Tema"
          name="theme"
          onChange={(event) => handleChange('theme', event.target.value)}
          select
          SelectProps={{ native: true }}
          value={values.theme}
          variant="outlined"
        >
          {Object.keys(THEMES).map((theme) => (
            <option
              key={theme}
              value={theme}
            >
              {theme
                .split('_')
                .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                .join(' ')}
            </option>
          ))}
        </TextField>
      </Box>
      <Box
        sx={{
          mt: 2,
          px: 1.5
        }}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={values.responsiveFontSizes}
              color="primary"
              edge="start"
              name="direction"
              onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
            />
          )}
          label={(
            <div>
              Tamaño de fuente dinámica
              <Typography
                color="textSecondary"
                component="p"
                variant="caption"
              >
                Ajusta fuentes para pantallas pequeñas
              </Typography>
            </div>
          )}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
          px: 1.5
        }}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={values.compact}
              color="primary"
              edge="start"
              name="compact"
              onChange={(event) => handleChange('compact', event.target.checked)}
            />
          )}
          label={(
            <div>
              Compacto
              <Typography
                color="textSecondary"
                component="p"
                variant="caption"
              >
                Ancho fijo en pantallas anchas
              </Typography>
            </div>
          )}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
          px: 1.5
        }}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={values.roundedCorners}
              color="primary"
              edge="start"
              name="roundedCorners"
              onChange={(event) => handleChange('roundedCorners', event.target.checked)}
            />
          )}
          label={(
            <div>
              Bordes Curvos
              <Typography
                color="textSecondary"
                component="p"
                variant="caption"
              >
                Bordes curvos o rectos
              </Typography>
            </div>
          )}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Button
          color="primary"
          fullWidth
          onClick={handleSave}
          variant="contained"
        >
          Guardar Configuración
        </Button>
      </Box>
    </Drawer>
  );
};

export default SettingsDrawer;

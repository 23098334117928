import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
// import useScrollReset from './hooks/useScrollReset'; BORRAR
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import './App.css';

function App() {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  // useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster
          position="top-center"
        /* toastOptions={{
         // Default options for specific types
         success: {
           style: {
             background: '#2e7d32',
             color: '#ffffff',
             fontWeight: '400',

           },
           iconTheme: {
             primary: 'white',
             secondary: '#2e7d32',
           },
         },
         error: {
           style: {
             background: '#d32f2f',
             color: '#ffffff',
             fontWeight: '400',
           },
           iconTheme: {
             primary: 'white',
             secondary: '#d32f2f',
           },
         },
         warning: {
           style: {
             background: '#ed6c02',
             color: '#ffffff',
             fontWeight: '400',
           },
           iconTheme: {
             primary: 'white',
             secondary: '#ed6c02',
           },
         },
         info: {
           style: {
             background: '#0288d1',
             color: '#ffffff',
             fontWeight: '400',
           },
           iconTheme: {
             primary: 'white',
             secondary: '#0288d1',
           },
         },
       }} */
        />
        <SettingsDrawer />
        {auth.isInitialized ? content : <SplashScreen />}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;

import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import BriefcaseIcon from '../../icons/Briefcase';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
import CollectionIcon from '../../icons/Collection';
import CallSplitOutlinedIcon from '@mui/icons-material/CallSplitOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import UsersIcon from '../../icons/Users';
import Logo from '../Logoblack';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import CheckRole from '../../utils/checkRole';
import useAuth from '../../hooks/useAuth';
import useParameters from '../../hooks/useParameters';

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Panel de Control',
        permiso: 'f3f',
        path: '/panel',
        icon: <ChartSquareBarIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Gestion',
    items: [
      {
        title: 'Clientes',
        path: '/gestion/clientes',
        icon: <RecentActorsIcon fontSize="small" />,
        children: [
          {
            title: 'Clientes',
            permiso: 'f4f',
            path: '/gestion/clientes'
          },
          {
            title: 'Garantías',
            permiso: 'f17f',
            path: '/gestion/garantias'
          }
        ]
      },
      {
        title: 'Deudores',
        path: '/gestion/deudores',
        icon: <BriefcaseIcon fontSize="small" />,
        children: [
          {
            title: 'Deudores',
            permiso: 'f21f',
            path: '/gestion/deudores'
          }
        ]
      },
      {
        title: 'Operaciones',
        path: '/gestion/operaciones',
        icon: <FolderOpenIcon fontSize="small" />,
        children: [
          {
            title: 'Prospectos',
            permiso: 'f65f',
            path: '/gestion/prospectos'
          },
          {
            title: 'Operaciones',
            permiso: 'f12f',
            path: '/gestion/operaciones'
          },
          {
            title: 'Giros',
            permiso: 'f81f',
            path: '/gestion/giros'
          }
        ]
      },
      {
        title: 'Documentos',
        icon: <AccountBalanceIcon fontSize="small" />,
        path: '/gestion/recaudacion',
        children: [
          {
            title: 'Documentos',
            permiso: 'f80f',
            path: '/gestion/recaudacion'
          },
          {
            title: 'Gastos',
            permiso: 'f36f',
            path: '/gestion/gastos'
          },
          {
            title: 'No Cedidos',
            permiso: 'f53f',
            path: '/gestion/nocedidos'
          },
          {
            title: 'Recaud. Extra',
            permiso: 'f69f',
            path: '/gestion/recaudextra'
          },
          {
            title: 'Excedentes',
            permiso: 'f58f',
            path: '/gestion/excedentes'
          },
          {
            title: 'Actividades',
            permiso: 'f2f',
            path: '/gestion/actividades'
          },
        ]
      },
      {
        title: 'Cobranza',
        path: '/gestion/cobranza',
        icon: <ReceiptIcon fontSize="small" />,
        children: [
          {
            title: 'Cobranza',
            permiso: 'f79f',
            path: '/gestion/cobranza'
          }
        ]
      },
      {
        title: 'Contabilidad',
        path: '/gestion/contabilidad',
        icon: <LibraryBooksOutlinedIcon fontSize="small" />,
        children: [
          {
            title: 'Libro Diario',
            permiso: 'f78f',
            path: '/gestion/contabilidad'
          },
          {
            title: 'Facturacion',
            permiso: 'f76f',
            path: '/gestion/facturacion'
          }
        ]
      },
      {
        title: 'Reportes',
        path: '/gestion/reportes',
        icon: <ClipboardListIcon fontSize="small" />,
        children: [
          {
            title: 'Reportes',
            permiso: 'f15f',
            path: '/gestion/reportes'
          }
        ]
      }
    ]
  },
  {
    title: 'Configuracion',
    items: [
      {
        title: 'Usuarios',
        path: '/configuracion/usuarios',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: 'Usuarios',
            permiso: 'f1f',
            path: '/configuracion/usuarios'
          }
        ]
      },
      {
        title: 'Mantenedores',
        path: '/configuracion/mantenedores',
        icon: <CollectionIcon fontSize="small" />,
        children: [
          {
            title: 'Mantenedores',
            permiso: 'f16f',
            path: '/configuracion/mantenedores'
          },
        ]
      },
      {
        title: 'Flujos de Trabajo',
        path: '/configuracion/flujos',
        icon: <CallSplitOutlinedIcon fontSize="small" />,
        children: [
          {
            title: 'Flujos',
            permiso: 'f45f',
            path: '/configuracion/flujos'
          },
        ]
      },
    ]
  }
];

// const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

function checkSection(section) {
  // console.log(section);
  const results = [];
  if (section.items.some((obj) => 'permiso' in obj && CheckRole(obj.permiso))) { results.push(true); } else { results.push(false); }
  if (section.items.some((obj) => ('children' in obj) && (obj.children.some((obj2) => 'permiso' in obj2 && CheckRole(obj2.permiso))))) { results.push(true); } else { results.push(false); }

  // console.log(results.includes(true));
  return (results.includes(true));
}

function DashboardSidebar(props) {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { timeDatesDB } = useParameters(); // Change
  const { user } = useAuth(); // Change
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('2000px'));
  // const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg')); change

  // console.log(timeDatesDB);
  // console.log(user);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (checkSection(section) && (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                },
                '& .MuiListSubheader-root': {
                  fontSize: '0.875rem',
                  color: 'text.primary',
                  fontWeight: 700,
                }
              }}
              {...section}
            />
          )))}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="body1"
            >
              {new Date(timeDatesDB[user.sucursal.value]?.date).toLocaleDateString('es-CL', { year: 'numeric', month: 'long', day: 'numeric' })}
            </Typography>
          </Box>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;

const LogoMobile = (props) => { // eslint-disable-line
  return (
    <img
      alt="Logo"
      src="/static/logo_mobile.png"
      {...props}
    />
  );
};

export default LogoMobile;

import { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  FormHelperText,
  TextField
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';

function LoginFirebase(props) {
  const mounted = useMounted();
  const { signInWithEmailAndPassword } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div {...props}>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup
          .object()
          .shape({
            email: Yup
              .string()
              .email('El correo electrónico debe ser válido')
              .max(255)
              .required('El correo electrónico es requerido'),
            password: Yup
              .string()
              .max(255)
              .required('La contraseña es requerida')
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setIsLoading(true);

          try {
            await signInWithEmailAndPassword(values.email, values.password);

            if (mounted.current) {
              setStatus({ success: true });
            }
          } catch (err) {
            console.error(err);
            if (mounted.current) {
              setStatus({ success: false });
              setErrors({ submit: 'Correo electrónico o contraseña inválida.' });
              setSubmitting(false);
              setIsLoading(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => ( // aca iba isSubmitting,
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Correo Electrónico"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Contraseña"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <LoadingButton
                color="primary"
                startIcon={<LoginIcon fontSize="small" />}
                disabled={isLoading}
                loading={isLoading}
                loadingPosition="start"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Ingresar
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default LoginFirebase;

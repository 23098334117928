import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/panel/perfil" />;
  }

  return <>{ children }</>; // eslint-disable-line
}

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;

import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import firebase, { db } from '../lib/firebase';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  platform: 'Firebase',
  signInWithEmailAndPassword: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

const getDBUser = async (userid) => {
  try {
    const snapshot = await db.collection('users').doc(userid).get();
    return snapshot.data();
  } catch (error) {
    console.log('Error getting user extra data document:', error);
    return null;
  }
};

export function AuthProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // Here I extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.
      getDBUser(user.uid).then((userData) => {
        // console.log(userData);

        firebase.auth().currentUser.getIdTokenResult(/* forceRefresh */ true).then((idToken) => {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                uid: user.uid,
                idToken: idToken.token,
                avatar: user.photoURL,
                email: user.email,
                name: user.displayName || user.email,
                role: idToken.claims.role,
                sucu: idToken.claims.sucu,
                reportes: userData?.perfil?.reportes || [],
                city: userData?.city ? userData.city : '',
                country: userData?.country ? userData.country : '',
                phone1: userData?.phone1 ? userData.phone1 : '',
                phone2: userData?.phone2 ? userData.phone2 : '',
                state: userData?.state ? userData.state : '',
                organizacion: userData.organizacion,
                sucursal: userData.sucursal,
                position: userData.position,
                perfil: userData.perfil,
                notifications: userData?.notifications ? userData.notifications : false,
                externo: userData?.externo ? userData.externo : false,
                clientIDs: userData?.clientIDs ? userData.clientIDs : [],
              }
            }
          });
        }).catch((error) => {
          console.error(error);
        });
      });
    } else {
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  }), [dispatch]);

  const signInWithEmailAndPassword = (
    email,
    password
  ) => firebase.auth().signInWithEmailAndPassword(email, password);

  const passwordRecovery = (email) => firebase.auth().sendPasswordResetEmail(email);

  const logout = async () => {
    await firebase.auth().signOut();
  };

  // check and refresh itToken if necesary - aun no funciona - change
  const getCurrentUserAuthorization = async () => {
    const currentUser = firebase.auth().currentUser; // eslint-disable-line
    if (currentUser) {
      const token = await currentUser.getIdToken();
      return token;
    }
    return '';
  };

  const updateProfile = async (user, name, avatar, phone1, phone2, notifications, externo, clientIDs) => { // Toda esta funcion es para actualizar desde cualquier componente el nombre, el URL del avatar, phone, y notifications del usuario logeado.
    if (!firebase.auth().currentUser) return;

    firebase.auth().currentUser.getIdTokenResult(/* forceRefresh */ true).then((idToken) => {
      // console.log(user);
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user: {
            uid: user.uid, // viene del user object
            idToken: idToken.token,
            avatar,
            email: user.email,
            name,
            role: idToken.claims.role,
            sucu: idToken.claims.sucu,
            reportes: user.perfil.reportes,
            city: user.city,
            country: user.country,
            phone1,
            phone2,
            state: user.state,
            organizacion: user.organizacion,
            sucursal: user.sucursal,
            position: user.position,
            perfil: user.perfil,
            notifications,
            externo,
            clientIDs
          }
        }
      });
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <AuthContext.Provider
      value={{ // eslint-disable-line
        ...state,
        platform: 'Firebase',
        signInWithEmailAndPassword,
        passwordRecovery,
        logout,
        updateProfile,
        getCurrentUserAuthorization
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
